<template>
    <DetailItemEditCard item-name="werknemers" :promises.sync="promises"
      title="Nieuwe werknemer aanmaken" confirmationMessage="Werknemer werd succesvol aangemaakt" update-store-action="usersModule/createUser"
      :update-store-action-payload="user" :detail-view-route-location="{name: RouteNames.USER_OVERVIEW}" :success-callback="userCreatedSuccessCallback">
      <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="user.firstName" persistent-placeholder label="Voornaam" type="text" required :rules="getRequiredRules('Voornaam moet ingevuld worden')"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="user.lastName" persistent-placeholder label="Achternaam" type="text" required :rules="getRequiredRules('Achternaam moet ingevuld worden')"/>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field v-model="user.email" persistent-placeholder label="Email" type="email" required :rules="getEmailRules()"/>
        </v-col>
        <v-col cols="12" md="6">
            <FetchOptionsSelect required persistent-placeholder label="Werkrooster"
                                action="workSchedulesModule/fetchWorkSchedules" v-model="user.workSchedule"
                                item-text="name" item-value="name" :rules="getRequiredRules('Werkrooster moet ingevuld worden')"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="user.statute" persistent-placeholder label="Statuut" type="text" required :rules="getRequiredRules('Statuut moet ingevuld worden')"/>
        </v-col>
        <v-col cols="12" md="6">
            <FetchOptionsSelect persistent-placeholder multiple action="usersModule/fetchRoles" type="text" v-model="user.roles" label="Rol" 
            required item-value="value" :translationFunction="role => translateRole(role.roleName)" :rules="roleRules"/>
        </v-col>
        <v-col v-if="isCIAOEnabled" cols="12" md="6">
          <v-text-field v-model="user.taxIdentificationNumber" persistent-placeholder label="Rijksregisternummer" type="text" required :rules="getRequiredRules('Rijksregisternummer moet ingevuld worden')"/>
        </v-col>
        <v-col cols="12">
            <PasswordInput :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" persistent-placeholder v-model="user.password" :show-button="true" show-button-text="Stel wachtwoord in"/>
        </v-col>
        <v-col class="flex-grow-0">
            <v-switch v-model="user.active" :label="user.active ? 'Actief' : 'Inactief'"></v-switch>
        </v-col>
    </v-row>
    </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import RouteNames from "@/router/RouteNames";
import {translateRole} from "@/shared/utils/translateUtils.js"
import {getRequiredRules, getEmailRules, getNotEmptyArrayRules} from "@/shared/utils/inputRulesUtils.js"
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";
import PasswordInput from "@/components/useroverview/PasswordInput.vue";
import {authenticatedUser} from "@/shared/utils/authenticationUtils";
import {organizationHasModule} from "@/shared/utils/authenticationUtils"
import ModuleType from "@/shared/enums/moduleType"

export default {
    name: "UserCreate",
    components: {
        DetailItemEditCard,
        FetchOptionsSelect,
        PasswordInput
    },
    data() {
        return {
            RouteNames,
            user: {
                active: true,
                resetBaseUrl: window.location.origin + "/password-reset-token",
                organizationId: authenticatedUser().organizationId,
            },
            promises: []
        }
    },
    methods: {
        translateRole,
        getRequiredRules,
        getEmailRules,
        userCreatedSuccessCallback(buildbaseUserResponse) {
            this.$router.push({name: RouteNames.USER_DETAIL, params: {userId: buildbaseUserResponse.id}})
        }
    },
    computed: {
        roleRules() {
            return [...getRequiredRules('Rol moet ingevuld worden'), ...getNotEmptyArrayRules('Selecteer minstens 1 rol')]
        },
        userPayload() {
            return {
                ...this.user,
                
            }
        },        
        isCIAOEnabled() {
            return organizationHasModule([ModuleType.LOCATION_REGISTRATION])
        },
    }
}
</script>